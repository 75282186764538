/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'antd/dist/antd.css';
import './src/styles/global.css';
import 'react-sortable-tree/style.css';

import Provider from './src/context/GlobalContextProvider';

export const wrapRootElement = Provider;
