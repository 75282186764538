// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-admin-users-tsx": () => import("./../../../src/pages/admin/users.tsx" /* webpackChunkName: "component---src-pages-admin-users-tsx" */),
  "component---src-pages-editor-tsx": () => import("./../../../src/pages/editor.tsx" /* webpackChunkName: "component---src-pages-editor-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */)
}

