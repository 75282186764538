import React, { MutableRefObject, useState } from 'react';

import { AxiosResponse } from 'axios';
import moment from 'moment';
import { CookiesProvider } from 'react-cookie';
import { TinyMCE, Editor } from 'tinymce';

import { apiRequester } from '../utility';
export interface GlobalContextType {
    editor: MutableRefObject<Editor | null>;
    setEditor: (editor: MutableRefObject<Editor | null>) => void;
    titlePage: AutoAPA.TitlePage;
    setTitlePage: React.Dispatch<React.SetStateAction<AutoAPA.TitlePage>>;
    html: string;
    setHtml: React.Dispatch<React.SetStateAction<string>>;
    printableHtml: string;
    plagarismReports: Array<AutoAPA.PalagarismReport>;
    setPlagarismReports: React.Dispatch<React.SetStateAction<Array<AutoAPA.PalagarismReport>>>;
    plagarismReportsPromises: Array<Promise<AxiosResponse<any>>>;
    setPlagarismReportsPromises: React.Dispatch<React.SetStateAction<Array<Promise<AxiosResponse<any>>>>>;
    notifications: any;
    setNotifications: React.Dispatch<React.SetStateAction<any>>;
    setPrintableHtml: React.Dispatch<React.SetStateAction<string>>;
    references: AutoAPA.Reference[];
    setReferences: React.Dispatch<React.SetStateAction<AutoAPA.Reference[]>>;
    activeReference: AutoAPA.Reference | null;
    setActiveReference: React.Dispatch<React.SetStateAction<AutoAPA.Reference | null>>;
    editorLoaded: boolean;
    setEditorLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    loggedInUser?: Users.User;
    setLoggedInUser: React.Dispatch<React.SetStateAction<Users.User | undefined>>;
    papers: Papers.Paper[];
    setPapers: React.Dispatch<React.SetStateAction<Papers.Paper[]>>;
    activePaper?: Papers.Paper;
    setActivePaper: React.Dispatch<React.SetStateAction<Papers.Paper | undefined>>;
    sharePaper?: Papers.Paper;
    setSharePaper: React.Dispatch<React.SetStateAction<Papers.Paper | undefined>>;
    reviewPaper?: Papers.Paper;
    setReviewPaper: React.Dispatch<React.SetStateAction<Papers.Paper | undefined>>;
    paperPagination: {
        total: number;
        limit: number;
        skip: number;
        refresh: boolean;
    };
    setPaperPagination: React.Dispatch<
        React.SetStateAction<{
            total: number;
            limit: number;
            skip: number;
            refresh: boolean;
        }>
    >;
    paperSearch: string;
    setPaperSearch: React.Dispatch<React.SetStateAction<string>>;
    paperListType: 'shared' | 'own';
    setPaperListType: React.Dispatch<React.SetStateAction<'shared' | 'own'>>;
    showReferenceCheckDrawer: boolean;
    setShowReferenceCheckDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    showExportModal: boolean;
    setShowExportModal: React.Dispatch<React.SetStateAction<boolean>>;
    performedSystemCheck: boolean;
    setPerformedSystemCheck: React.Dispatch<React.SetStateAction<boolean>>;
    systemCheckStack: string;
    setSystemCheckStack: React.Dispatch<React.SetStateAction<string>>;
}

export const GlobalContext = React.createContext<GlobalContextType>({
    editor: {} as MutableRefObject<Editor | null>,
    setEditor: () => {},
    titlePage: {},
    setTitlePage: () => {},
    html: '',
    plagarismReports: [],
    setPlagarismReports: () => {},
    plagarismReportsPromises: [],
    setPlagarismReportsPromises: () => {},
    notifications: [],
    setNotifications: () => {},
    setHtml: () => {},
    printableHtml: '',
    setPrintableHtml: () => {},
    references: [],
    setReferences: () => {},
    activeReference: {},
    setActiveReference: () => {},
    editorLoaded: false,
    setEditorLoaded: () => {},
    loggedInUser: {},
    setLoggedInUser: () => {},
    papers: [],
    setPapers: () => {},
    activePaper: {},
    setActivePaper: () => {},
    sharePaper: {},
    setSharePaper: () => {},
    reviewPaper: {},
    setReviewPaper: () => {},
    paperPagination: {
        total: 0,
        limit: 0,
        skip: 0,
        refresh: true,
    },
    setPaperPagination: () => {},
    paperSearch: '',
    setPaperSearch: () => {},
    paperListType: 'own',
    setPaperListType: () => {},
    showReferenceCheckDrawer: false,
    setShowReferenceCheckDrawer: () => {},
    performedSystemCheck: false,
    setPerformedSystemCheck: () => {},
    showExportModal: false,
    setShowExportModal: () => {},
    systemCheckStack: '',
    setSystemCheckStack: () => {},
});

const GlobalContextProvider = (props: { children: React.ReactChildren }) => {
    const [titlePage, setTitlePage] = useState<AutoAPA.TitlePage>({});
    const [editor, setEditor] = useState<MutableRefObject<Editor | null>>({} as MutableRefObject<Editor | null>);
    const [html, setHtml] = useState<string>('');
    const [references, setReferences] = useState<AutoAPA.Reference[]>([]);
    const [printableHtml, setPrintableHtml] = useState<string>('');
    const [activeReference, setActiveReference] = useState<AutoAPA.Reference | null>(null);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState<Users.User>();
    const [papers, setPapers] = useState<Papers.Paper[]>([]);
    const [activePaper, setActivePaper] = useState<Papers.Paper>();
    const [sharePaper, setSharePaper] = useState<Papers.Paper>();
    const [reviewPaper, setReviewPaper] = useState<Papers.Paper>();
    const [plagarismReports, setPlagarismReports] = useState<Array<AutoAPA.PalagarismReport>>([]);
    const [plagarismReportsPromises, setPlagarismReportsPromises] = useState<Array<Promise<AxiosResponse<any>>>>([]);
    const [notifications, setNotifications] = useState<string[]>([]);
    const [paperPagination, setPaperPagination] = useState({
        total: 0,
        limit: 10,
        skip: 0,
        refresh: true,
    });
    const [paperSearch, setPaperSearch] = useState('');
    const [paperListType, setPaperListType] = useState<'shared' | 'own'>('own');
    const [showReferenceCheckDrawer, setShowReferenceCheckDrawer] = useState(false);
    const [performedSystemCheck, setPerformedSystemCheck] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [systemCheckStack, setSystemCheckStack] = useState('');

    const globalContext: GlobalContextType = {
        editor,
        setEditor,
        titlePage,
        setTitlePage,
        html,
        setHtml,
        printableHtml,
        setPrintableHtml,
        plagarismReports,
        setPlagarismReports,
        plagarismReportsPromises,
        setPlagarismReportsPromises,
        notifications,
        setNotifications,
        references,
        setReferences,
        activeReference,
        setActiveReference,
        editorLoaded,
        setEditorLoaded,
        loggedInUser,
        setLoggedInUser,
        papers,
        setPapers,
        activePaper,
        setActivePaper,
        sharePaper,
        setSharePaper,
        paperPagination,
        setPaperPagination,
        paperSearch,
        setPaperSearch,
        paperListType,
        setPaperListType,
        showReferenceCheckDrawer,
        setShowReferenceCheckDrawer,
        reviewPaper,
        setReviewPaper,
        performedSystemCheck,
        setPerformedSystemCheck,
        showExportModal,
        setShowExportModal,
        systemCheckStack,
        setSystemCheckStack,
    };

    return <GlobalContext.Provider value={globalContext}>{props.children}</GlobalContext.Provider>;
};

const wrapWithProvider = ({ element }: { element: React.ReactChildren }) => (
    <CookiesProvider>
        <GlobalContextProvider>{element}</GlobalContextProvider>
    </CookiesProvider>
);

export default wrapWithProvider;
